function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { CALL_API } from "../middleware/api";
import { urlSearchParams } from "../constants";
export var DELETE_CONTACT_REQUEST = "DELETE_CONTACT_REQUEST";
export var DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export var DELETE_CONTACT_FAILURE = "DELETE_CONTACT_FAILURE";
export var RESET_DELETE_CONTACT = "RESET_DELETE_CONTACT";
export var deleteContact = function deleteContact(contact) {
  return _defineProperty({
    contact: contact
  }, CALL_API, {
    types: [DELETE_CONTACT_REQUEST, DELETE_CONTACT_SUCCESS, DELETE_CONTACT_FAILURE],
    endpoint: "contacts/".concat(contact.id),
    init: {
      method: "DELETE",
      credentials: "same-origin"
    }
  });
};