function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import merge from "lodash/merge";
import * as ActionTypes from "../../actions";

// Updates an entity cache in response to any action with response.entities.
var userEntities = function userEntities() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    byId: {},
    byEmail: {},
    byUsername: {},
    byToken: {},
    suggestedById: {},
    featured: [],
    topInvestor: [],
    mediumRiskScore: [],
    highRiskScore: [],
    byCreatedRevinfoInvestmentItemId: {}
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ActionTypes.GET_USERBYID_SUCCESS:
      var byId = _objectSpread({}, state.byId);
      byId[action.id] = action.response;
      var byUsername = _objectSpread({}, state.byUsername);
      byUsername[action.response.username] = action.response;
      return Object.assign({}, state, {
        byId: byId,
        byUsername: byUsername
      });
    case ActionTypes.LOGIN_SUCCESS:
    case ActionTypes.GET_AUTHENTICATIONSTATUS_SUCCESS:
      var byId = action.response.userById;
      return merge({}, state, {
        byId: byId
      });
    case ActionTypes.GET_USERVIEWBYFOLLOWEEUSERNAMEANDAUTHUSERID_SUCCESS:
      var byId = action.response.userById;
      return merge({}, state, {
        byId: byId
      });
    case ActionTypes.GET_USERBYTOKEN_SUCCESS:
      var byToken = _objectSpread({}, state.byToken);
      byToken[action.token] = action.response;
      return Object.assign({}, state, {
        byToken: byToken
      });
    case ActionTypes.GET_USERS_SUCCESS:
    case ActionTypes.GET_USERSBYINVESTMENTACCOUNTBROKERID_SUCCESS:
      if (!action.response._embedded) return state;
      var byId = _objectSpread({}, state.byId);
      action.response._embedded.users.forEach(function (user) {
        return byId[user.id] = user;
      });
      return Object.assign({}, state, {
        byId: byId
      });
    case ActionTypes.POST_USERPROFILEPICTURE_SUCCESS:
      var byEmail = _objectSpread({}, state.byEmail);
      var user = byEmail[action.user.email];
      if (user) {
        var user = _objectSpread({}, user);
        user.profilePicture = action.response._embedded;
        byEmail[user.email] = user;
      }
      var byId = _objectSpread({}, state.byId);
      var user = byId[action.user.id];
      if (user) {
        var user = _objectSpread({}, user);
        user.profilePicture = action.response._embedded;
        byId[action.user.id] = user;
      }
      var byUsername = _objectSpread({}, state.byUsername);
      var user = byUsername[action.user.username];
      if (user) {
        var user = _objectSpread({}, user);
        user.profilePicture = action.response._embedded;
        byUsername[user.username] = user;
      }
      return Object.assign({}, state, {
        byEmail: byEmail,
        byId: byId,
        byUsername: byUsername
      });
    case ActionTypes.POST_USERPROFILEBACKGROUNDPICTURE_SUCCESS:
      var byEmail = _objectSpread({}, state.byEmail);
      var user = byEmail[action.user.email];
      if (user) {
        var user = _objectSpread({}, user);
        user.profileBackgroundPicture = action.response._embedded;
        byEmail[user.email] = user;
      }
      var byId = _objectSpread({}, state.byId);
      var user = byId[action.user.id];
      if (user) {
        var user = _objectSpread({}, user);
        user.profileBackgroundPicture = action.response._embedded;
        byId[action.user.id] = user;
      }
      var byUsername = _objectSpread({}, state.byUsername);
      var user = byUsername[action.user.username];
      if (user) {
        var user = _objectSpread({}, user);
        user.profileBackgroundPicture = action.response._embedded;
        byUsername[user.username] = user;
      }
      return Object.assign({}, state, {
        byEmail: byEmail,
        byId: byId,
        byUsername: byUsername
      });
    case ActionTypes.POST_USER_SUCCESS:
      var byEmail = _objectSpread({}, state.byEmail);
      byEmail[action.response._embedded.email] = action.response._embedded;
      var byId = _objectSpread({}, state.byId);
      byId[action.response._embedded.id] = action.response._embedded;
      var byUsername = _objectSpread({}, state.byUsername);
      byUsername[action.response._embedded.username] = action.response._embedded;
      return Object.assign({}, state, {
        byEmail: byEmail,
        byId: byId,
        byUsername: byUsername
      });
    case ActionTypes.POST_USEREXCLUSIONPREFERENCES_SUCCESS:
      var byEmail = _objectSpread({}, state.byEmail);
      var user = byEmail[action.user.email];
      if (user) {
        var user = _objectSpread({}, user);
        user.exclusionPreferences = action.response._embedded;
        byEmail[user.email] = user;
      }
      var byId = _objectSpread({}, state.byId);
      var user = byId[action.user.id];
      if (user) {
        var user = _objectSpread({}, user);
        user.exclusionPreferences = action.response._embedded;
        byId[action.user.id] = user;
      }
      var byUsername = _objectSpread({}, state.byUsername);
      var user = byUsername[action.user.username];
      if (user) {
        var user = _objectSpread({}, user);
        user.exclusionPreferences = action.response._embedded;
        byUsername[user.username] = user;
      }
      return Object.assign({}, state, {
        byEmail: byEmail,
        byId: byId,
        byUsername: byUsername
      });
    case ActionTypes.POST_USERLANGUAGEPREFERENCES_SUCCESS:
      var byEmail = _objectSpread({}, state.byEmail);
      var user = byEmail[action.user.email];
      if (user) {
        var user = _objectSpread({}, user);
        user.languagePreferences = action.response._embedded;
        byEmail[user.email] = user;
      }
      var byId = _objectSpread({}, state.byId);
      var user = byId[action.user.id];
      if (user) {
        var user = _objectSpread({}, user);
        user.languagePreferences = action.response._embedded;
        byId[action.user.id] = user;
      }
      var byUsername = _objectSpread({}, state.byUsername);
      var user = byUsername[action.user.username];
      if (user) {
        var user = _objectSpread({}, user);
        user.languagePreferences = action.response._embedded;
        byUsername[user.username] = user;
      }
      return Object.assign({}, state, {
        byEmail: byEmail,
        byId: byId,
        byUsername: byUsername
      });
    case ActionTypes.GET_EVENTSASIDEVIEWBYAUTHUSERID_SUCCESS:
      var suggestedById = action.response.suggestedUsersById;
      return merge({}, state, {
        suggestedById: suggestedById
      });
    case ActionTypes.GET_USERSVIEWBYAUTHUSERID_SUCCESS:
      var featured = action.response.featuredUsers;
      var topInvestor = action.response.topInvestorUsers;
      var mediumRiskScore = action.response.mediumRiskScoreUsers;
      var highRiskScore = action.response.highRiskScoreUsers;
      return Object.assign({}, state, {
        featured: featured,
        topInvestor: topInvestor,
        mediumRiskScore: mediumRiskScore,
        highRiskScore: highRiskScore
      });
    case ActionTypes.GET_INVESTMENTITEMVIEWBYINVESTMENTITEMID_SUCCESS:
      var byCreatedRevinfoInvestmentItemId = action.response.usersByCreatedRevinfoInvestmentItemId;
      return merge({}, state, {
        byCreatedRevinfoInvestmentItemId: byCreatedRevinfoInvestmentItemId
      });
    case ActionTypes.GET_BROKERVIEWBYBROKERIDANDAUTHUSERID_SUCCESS:
      var byCreatedRevinfoBrokerId = action.response.usersByCreatedRevinfoBrokerId;
      return merge({}, state, {
        byCreatedRevinfoBrokerId: byCreatedRevinfoBrokerId
      });
    case ActionTypes.GET_SUGGESTEDUSERSBYID_SUCCESS:
      var suggestedById = _objectSpread({}, state.suggestedById);
      suggestedById[action.id] = action.response._embedded.users;
      return merge({}, state, {
        suggestedById: suggestedById
      });
    default:
      return state;
  }
};
export default userEntities;